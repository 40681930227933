import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React from "react"
import Layout from "../../components/_App/layout"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import FileConverter from "../../components/ConvertFiles/FileConverter"
import ToolInfo from "../../components/PNGToWebP/ToolInfo"

const parent = [
  {
    name: "Tools",
    link: "tools"
  }
]

function PngToWebP() {

  return (
    <Layout>
      <Seo title="PNG to WebP Converter Tool" />
      <Navbar />
      <div className="contact-area pt-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>PNG to WebP</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle="PNG to WebP" parent={parent} />
          <FileConverter acceptFile={'image/png'} format={"image/webp"}/>
        </div>
      </div>
      <ToolInfo/>
      <Partner />

      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default PngToWebP